import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BrowserRouter, Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Home from './components/home/Home';
import Policy from './components/policy/Policy';
import TestFaucet from './components/test-faucet/test-faucet';
import Head from './components/header/Head';
import history from './history';

export default (prop) => (
    <BrowserRouter history={history}>
        <Head values={prop.data}></Head>
        <Switch>
            <Route exact path="/" component={() => <Home values={prop.data} />} />
            <Route exact path="/policy" component={() => <Policy values={prop.data} />} />
            <Route exact path="/test-faucet" component={() => <TestFaucet values={prop.data} />} />
            {/* <Route component={() => <Home values={prop.data} />} /> */}
        </Switch>

        <footer id="footer">
            <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong><span>TronSender</span></strong> 2020. All Rights Reserved &nbsp;&nbsp;&nbsp;&nbsp;| <Nav.Link as={Link} to="/policy" style={{ width: '200px', display: 'inline' }}><span>Privacy Policy</span></Nav.Link>
                </div>
            </div>
        </footer>
    </BrowserRouter>
);
