import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import $ from 'jquery';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { SMART_CONTRACT_ADDRESS_MAINNET, SMART_CONTRACT_ADDRESS_SHASTA } from '../../config';

export default class Head extends Component {
    userAddress = '';
    constructor(props) {
        super(props);
        this.state = {
            currentPath: window.location.pathname,
            networkType: '',
            tronUrl: ''
        }
        toastr.options = {
            // positionClass: 'toast-top-full-width',
            hideDuration: 300,
            timeOut: 60000
        }
    }

    componentDidMount() {
        this.setPathName();
    }

    setPathName() {
        setTimeout(() => {
            this.setState({
                currentPath: window.location.pathname
            })
            this.setPathName()
        }, 1000)
    }

    connectWallet() {
        if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
            let eventServer = window.tronWeb.eventServer;
            if (eventServer && eventServer.host) {
                if (eventServer.host.indexOf('shasta') >= 0) {
                    this.setConnectedWallet("Shasta Testnet", 'https://shasta.tronscan.org/#/address/');
                } else if (eventServer.host.indexOf('https://api.trongrid.io') >= 0) {
                    this.setConnectedWallet("Mainnet (TronGrid)", 'https://tronscan.io/#/address/');
                } else {
                    this.props.values.isNetworkConnected = false;
                    toastr.error("Unable to connect tron")
                }
            } else {
                this.props.values.isNetworkConnected = false;
                toastr.error("Unable to connect tron")
            }
        } else {
            this.props.values.isNetworkConnected = false;
            toastr.error("Unable to connect tron")
        }
    }

    setConnectedWallet(networkType, tronUrl) {
        this.userAddress = window.tronWeb.defaultAddress.base58;
        this.props.values.isNetworkConnected = true;
        this.setState({ networkType: networkType, tronUrl: tronUrl })
        $('#connect').trigger('click')
    }

    resetPage() {
        window.location.reload();
    }

    render() {
        return (
            <div>
                <header id="header" className="fixed-top">
                    <div className="social">
                        <ul>
                            {/* <li><a href="https://tronfoundation.medium.com/tron-2021-global-defi-hackathon-developer-contest-is-in-full-swing-first-winners-announced-3138021ac443" target="_blank"><img src="assets/img/award.png" style={{ width: '60px' }} ></img></a></li> */}
                            <li><a href="https://www.facebook.com/Tron-Sender-101873368450549/" target="_blank"><i className="icofont-facebook"></i></a></li>
                            <li><a href="https://twitter.com/SenderTron" target="_blank"><i className="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/tron-sender/ ?" target="_blank"><i className="icofont-linkedin"></i></a></li>
                            <li><a href="https://t.me/joinchat/HCAFABS6chPPbkTzIWa8ag" target="_blank"><i className="icofont-telegram"></i></a></li>
                            <li><a href="https://www.youtube.com/channel/UCE5EGQ_BzUu7PlIOHbv804w" target="_blank"><i className="icofont-youtube"></i></a></li>
                            <li><a href="https://medium.com/@tronsender" target="_blank"><img src="assets/img/medium.png" style={{ width: '25px' }} ></img></a></li>
                            <li><a href={"https://tronscan.org/#/contract/" + SMART_CONTRACT_ADDRESS_MAINNET} target="_blank"><img src="assets/img/tronscan.jpg" style={{ width: '25px' }} ></img></a></li>
                        </ul>
                    </div>
                    <div className="container d-flex align-items-center">
                        <Nav.Link as={Link} to="/" onClick={() => this.resetPage()} className="logo ml-auto mr-auto"><img src="assets/img/logo.png" alt="" className="img-fluid" /></Nav.Link>
                    </div>

                    <div className="connect">
                        <ul>
                            <li>{this.userAddress ? (
                                <div id="networkContainer" style={{ display: 'block' }}>
                                    <span className="liveNetwork"></span>
                                    <span className="networkId"> {this.state.networkType} </span>
                                    <br />
                                    <span><a href={this.state.tronUrl + this.userAddress} target="_blank" style={{ fontSize: '13px' }}>{this.userAddress}</a></span>
                                </div>
                            ) : ''}</li>
                            <li>{this.userAddress ? '' : (
                                <button className="btn-gen animated fadeInUp glow-on-hover" onClick={() => this.connectWallet()}><span className="glow-on-hover2"></span>Connect Wallet</button>
                            )}</li>
                        </ul>
                    </div>
                </header>
                {
                    this.state.currentPath != '/policy' ? (<section id="hero" className="d-flex align-items-center" style={{ height: 'auto', paddingTop: '75px', background: 'url(../img/tokenform-bg.jpg) center center', backgroundSize: 'cover' }}>
                        <div className="container position-relative text-center" data-aos="zoom-in" data-aos-delay="100" style={{maxWidth:'100%'}}>
                            <div className="row">

                                <div className="col-md-3">
                                    <img src='/assets/img/audit_tron.png' style={{ width: '175px' }} />
                                </div>
                                <div className="col-md-6" style={{ alignSelf: 'center' }}>
                                    <p style={{ paddingTop: '45px' }}>TronSender is a tool for distributing Tron, TRC10 and TRC20 tokens to multiple wallet addresses from a CSV file.</p>
                                </div>
                                <div className="col-md-3">
                                    <a href="https://tronfoundation.medium.com/tron-2021-global-defi-hackathon-developer-contest-is-in-full-swing-first-winners-announced-3138021ac443" target="_blank"><img src="assets/img/award.png" style={{ width: '200px' }} ></img></a>
                                </div>
                            </div>
                        </div>
                    </section>
                    ) : ''
                }
            </div>
        )
    }
}