import React, { Component } from "react";

export default class TestFaucet extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container" style={{ paddingTop: '100px' }}>
                <h1 className="text-center">Test Faucet</h1>
            </div>
        );
    }
}