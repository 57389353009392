import React, { Component } from "react";
import { BrowserRouter, Link } from 'react-router-dom';
import Routes from './Routes';
import './App.css';

export default class App extends Component {

  userAddress = '';
  updateUserStats = true;
  siteUrl = window.location.origin;
  constructor(props) {
    super(props);
    this.state = {
      currentPath: window.location.pathname,
      isNetworkConnected: false,
    }

    this.setPathName();
  }

  setPathName() {
    setTimeout(() => {
      this.setState({
        currentPath: window.location.pathname
      })
    }, 1000)
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter >
          <Routes data={this.state} />
        </BrowserRouter>
      </div>
    )
  }
}
